import React from "react";
import { navigate } from "gatsby";

import "./link.scss";

const Link = ({ path, isActive, onClick, children }) => {
  const onNavigate = () => {
    if (onClick) {
      onClick(path);
    }

    setTimeout(() => {
      navigate(`/${path}/`);
    }, 120);
  };

  return (
    <div
      className={`Link ${isActive ? "Link--isActive" : ""}`}
      onClick={onNavigate}
    >
      {children}
    </div>
  );
};

export default Link;
