import React from "react";

import "./header.scss";

const Header = () => (
  <header className="Header">
    <div className="Header__firstname">Linden</div> <span className="Header__surname">Holt-Whittaker</span>
  </header>
);

export default Header;
