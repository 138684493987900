import React, { useState, useEffect } from "react";

import Page, { Header, Body, Content } from "src/components/page";
import { NavBar, NavItem, Link } from "src/components/navigation";
import "./home.scss";

const navItems = [
  { name: "Me", path: "me" },
  { name: "Generative Art", path: "generative-art" },
  { name: "Games", path: "games" },
];

const navPaths = navItems.map(({ path }) => path);
const checkIndex = i => navPaths.indexOf(i);

const Home = ({ children, location: { pathname } }) => {
  const getPath = path => path.split("/")[1];
  const currentPath = getPath(pathname);
  const isHomePath = !currentPath;

  const initialActive = isHomePath ? null : checkIndex(currentPath);

  const [activeNav, setActive] = useState(initialActive);

  useEffect(() => {
    setActive(initialActive);
  }, [initialActive]);

  let isLoading = activeNav !== initialActive;

  const onNavClick = selected => setActive(checkIndex(getPath(selected)));

  return (
    <Page>
      <div className="HomeContainer">
        <div className="HomeContent">
          <div
            className={`HomeHeader ${isHomePath ? "HomeHeader--isBig" : ""}`}
          >
            <div className="HomeHeaderContent">
              <Link path="/" onClick={onNavClick}>
                <Header text="Linden Holt-Whittaker" />
                <div className="HomeHeaderBackground" />
              </Link>

              <NavBar>
                {navItems.map(({ name, path }, i) => (
                  <NavItem
                    key={name}
                    name={name}
                    path={`/${path}`}
                    isActive={activeNav === i}
                    onClick={onNavClick}
                  />
                ))}
              </NavBar>
            </div>
          </div>

          <div
            className={`HomeBody ${
              isLoading ? "HomeBody--isLoading" : "HomeBody--hasLoaded"
            }`}
          >
            <Body>
              <Content>{children}</Content>
            </Body>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Home;
