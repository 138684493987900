import React from "react";

import { Link } from "src/components/navigation";
import "./nav-item.scss";

const NavItem = ({ name, ...rest }) => (
  <Link {...rest}>
    <div className="NavItemTile">
      <div className="NavItemName">{name}</div>
    </div>
  </Link>
);

export default NavItem;
