import React from "react";

import "./nav-bar.scss";

const NavBar = ({ children }) => {
  const navItems = [];

  React.Children.forEach(children, child => {
    navItems.push(child);
  });

  return (
    <div className="NavBar">
      <div className="NavBar__items">{navItems}</div>
    </div>
  );
};

export default NavBar;
