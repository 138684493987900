// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-games-js": () => import("../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-generative-art-js": () => import("../src/pages/generative-art.js" /* webpackChunkName: "component---src-pages-generative-art-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-me-js": () => import("../src/pages/me.js" /* webpackChunkName: "component---src-pages-me-js" */)
}

