import React from "react";

import "./content.scss";

const Content = ({ children }) => (
  <div className="ContentContainer">
    <div className="Content">{children}</div>
  </div>
);

export default Content;
